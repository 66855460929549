import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { displayErrors } from '../helper';

class Banks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            id: '',
            code: '',
            name: '',
            swift: '',
            suspenseAccount: '',
            interbankSuspenseAccount: '',
            p2BSuspenseAccount: '',
            p2BInterbankSuspenseAccount: '',
            timeClosesOn: ''
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createBankHandler = this.createBankHandler.bind(this);
        this.createNewBankHandler = this.createNewBankHandler.bind(this);
        this.updateBankHandler = this.updateBankHandler.bind(this);
        this.deleteBankHandler = this.deleteBankHandler.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });
        this.setState({ code: childData.code });
        this.setState({ swift: childData.swift });
        this.setState({ suspenseAccount: childData.suspenseAccount });
        this.setState({ interbankSuspenseAccount: childData.interbankSuspenseAccount });
        this.setState({ p2BSuspenseAccount: childData.p2BSuspenseAccount });
        this.setState({ p2BInterbankSuspenseAccount: childData.p2BInterbankSuspenseAccount });
        this.setState({ timeClosesOn: childData.timeClosesOn });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    createBankHandler() {
        this.setState({ open: true });
    };

    createNewBankHandler(values) {
        const { code, name, swift, suspenseAccount, interbankSuspenseAccount, p2BSuspenseAccount, p2BInterbankSuspenseAccount, hh, mm } = values;

        let formattedHH = hh.toString(), formattedMM = mm.toString();
        formattedHH = `0${hh}`.slice(-2);;
        formattedMM = `0${mm}`.slice(-2);;
       

        let d = new Date();
        let year = d.getFullYear();
        let month = ("0" + (d.getMonth() + 1)).slice(-2);
        let date = (`0${d.getDate()}`).slice(-2);

        const dateStr = `${year}-${month}-${date}`;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bank/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": name,
                "code": code,
                "swift": swift,
                "suspenseAccount": suspenseAccount,
                "interbankSuspenseAccount": interbankSuspenseAccount,
                "p2BSuspenseAccount": p2BSuspenseAccount,
                "p2BInterbankSuspenseAccount": p2BInterbankSuspenseAccount,
                "timeClosesOn": `${dateStr}T${formattedHH}:${formattedMM}:44.156Z`
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ swift: '' });
                this.setState({ suspenseAccount: '' });
                this.setState({ interbankSuspenseAccount: '' });
                this.setState({ p2BSuspenseAccount: '' });
                this.setState({ p2BInterbankSuspenseAccount: '' });
                toast.success('Bank created successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        });
    };


    updateBankHandler(values) {
        const { id } = this.state;
        const { code, name, swift, suspenseAccount, interbankSuspenseAccount, p2BSuspenseAccount, p2BInterbankSuspenseAccount, hh, mm } = values;

        let formattedHH = hh.toString(), formattedMM = mm.toString();

        if (hh === 0 || formattedHH.length === 1) {
            formattedHH = `0${hh}`;
        } else {
            formattedHH = hh;
        }

        if (mm === 0 || formattedMM.length === 1) {
            formattedMM = `0${mm}`;
        } else {
            formattedMM = mm;
        }

        let d = new Date();
        let year = d.getFullYear();
        let month = ("0" + (d.getMonth() + 1)).slice(-2);
        let date = d.getDate();

        const dateStr = `${year}-${month}-${date}`;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bank/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "name": name,
                "code": code,
                "swift": swift,
                "suspenseAccount": suspenseAccount,
                "interbankSuspenseAccount": interbankSuspenseAccount,
                "p2BSuspenseAccount": p2BSuspenseAccount,
                "p2BInterbankSuspenseAccount": p2BInterbankSuspenseAccount,
                "timeClosesOn": `${dateStr}T${formattedHH}:${formattedMM}:44.156Z`
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ swift: '' });
                this.setState({ suspenseAccount: '' });
                this.setState({ interbankSuspenseAccount: '' });
                this.setState({ p2BSuspenseAccount: '' });
                this.setState({ p2BInterbankSuspenseAccount: '' });
                toast.success('Bank updated successfully!');
                this.setState({ isUpdateDialog: false });
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };


    deleteBankHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bank/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ swift: '' });
                this.setState({ suspenseAccount: '' });
                this.setState({ interbankSuspenseAccount: '' });
                this.setState({ p2BSuspenseAccount: '' });
                this.setState({ p2BInterbankSuspenseAccount: '' });
                toast.success('Bank deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    render() {
        const { name, code, swift, suspenseAccount, interbankSuspenseAccount, p2BSuspenseAccount, p2BInterbankSuspenseAccount, timeClosesOn, loading, open, isUpdateDialog, isDeleteDialog } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.createBankHandler} buttonTitle={'Create New Bank'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ name: name, code: code, swift: swift, suspenseAccount: suspenseAccount, interbankSuspenseAccount: interbankSuspenseAccount, p2BSuspenseAccount: p2BSuspenseAccount, p2BInterbankSuspenseAccount: p2BInterbankSuspenseAccount, hh: '', mm: '' }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewBankHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        code: Yup.string()
                            .required('This field is required.'),
                        swift: Yup.string()
                            .required('This field is required.'),
                        suspenseAccount: Yup.string()
                            .required('This field is required.'),
                        interbankSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        p2BSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        p2BInterbankSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        hh: Yup.number().required('This field is required.').lessThan(25, 'Invalid.'),
                        mm: Yup.number().required('This field is required.').lessThan(61, 'Invalid.'),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={open}
                            onCancel={() => {
                                this.setState({ open: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Bank'}
                            confirmText={'Create Bank'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.name && touched.name ? (
                                                <span className='formik-error-msg'>{errors.name}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.code && touched.code ? (
                                                <span className='formik-error-msg'>{errors.code}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Swift" type='text' name='swift' placeholder='Swift' value={values.swift} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.swift && touched.swift ? (
                                                <span className='formik-error-msg'>{errors.swift}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>COB Time</label>
                                            <Form.Group widths='equal' className='noMargin'>
                                                <Form.Input fluid type='number' name='hh' placeholder='hh' value={values.hh} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.hh && touched.hh ? (
                                                    <span className='formik-error-msg'>{errors.hh}</span>
                                                ) : null}

                                                <Form.Input fluid type='number' name='mm' placeholder='mm' value={values.mm} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.mm && touched.mm ? (
                                                    <span className='formik-error-msg'>{errors.mm}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="P2P Intrabank Suspense Account" type='text' name='suspenseAccount' placeholder='P2P Intrabank Suspense Account' value={values.suspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.suspenseAccount && touched.suspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.suspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="P2P Interbank Suspense Account" type='text' name='interbankSuspenseAccount' placeholder='P2P Interbank Suspense Account' value={values.interbankSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.interbankSuspenseAccount && touched.interbankSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.interbankSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="P2B Intrabank Suspense Account" type='text' name='p2BSuspenseAccount' placeholder='P2B Intrabank Suspense Account' value={values.p2BSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.p2BSuspenseAccount && touched.p2BSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.p2BSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="P2B Interbank Suspense Account" type='text' name='p2BInterbankSuspenseAccount' placeholder='P2B Interbank Suspense Account' value={values.p2BInterbankSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.p2BInterbankSuspenseAccount && touched.p2BInterbankSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.p2BInterbankSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ name: name, code: code, swift: swift, suspenseAccount: suspenseAccount, interbankSuspenseAccount: interbankSuspenseAccount, p2BSuspenseAccount: p2BSuspenseAccount, p2BInterbankSuspenseAccount: p2BInterbankSuspenseAccount, hh: timeClosesOn[11] + timeClosesOn[12], mm: timeClosesOn[14] + timeClosesOn[15] }}
                    onSubmit={values => {
                        this.updateBankHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        code: Yup.string()
                            .required('This field is required.'),
                        swift: Yup.string()
                            .required('This field is required.'),
                        suspenseAccount: Yup.string()
                            .required('This field is required.'),
                        interbankSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        p2BSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        p2BInterbankSuspenseAccount: Yup.string()
                            .required('This field is required.'),
                        hh: Yup.number().required('This field is required.').lessThan(25, 'Invalid.'),
                        mm: Yup.number().required('This field is required.').lessThan(61, 'Invalid.'),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Update Bank'}
                            confirmText={'Save'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.name && touched.name ? (
                                                <span className='formik-error-msg'>{errors.name}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.code && touched.code ? (
                                                <span className='formik-error-msg'>{errors.code}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Swift" type='text' name='swift' placeholder='Swift' value={values.swift} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.swift && touched.swift ? (
                                                <span className='formik-error-msg'>{errors.swift}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>COB Time</label>
                                            <Form.Group widths='equal' className='noMargin'>
                                                <Form.Input fluid type='number' name='hh' placeholder='hh' value={values.hh} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.hh && touched.hh ? (
                                                    <span className='formik-error-msg'>{errors.hh}</span>
                                                ) : null}

                                                <Form.Input fluid type='number' name='mm' placeholder='mm' value={values.mm} onChange={handleChange} onBlur={handleBlur} />
                                                {errors.mm && touched.mm ? (
                                                    <span className='formik-error-msg'>{errors.mm}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="P2P Intrabank Suspense Account" type='text' name='suspenseAccount' placeholder='P2P Intrabank Suspense Account' value={values.suspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.suspenseAccount && touched.suspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.suspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="P2P Interbank Suspense Account" type='text' name='interbankSuspenseAccount' placeholder='P2P Interbank Suspense Account' value={values.interbankSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.interbankSuspenseAccount && touched.interbankSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.interbankSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="P2B Intrabank Suspense Account" type='text' name='p2BSuspenseAccount' placeholder='P2B Intrabank Suspense Account' value={values.p2BSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.p2BSuspenseAccount && touched.p2BSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.p2BSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="P2B Interbank Suspense Account" type='text' name='p2BInterbankSuspenseAccount' placeholder='P2B Interbank Suspense Account' value={values.p2BInterbankSuspenseAccount} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.p2BInterbankSuspenseAccount && touched.p2BInterbankSuspenseAccount ? (
                                                <span className='formik-error-msg'>{errors.p2BInterbankSuspenseAccount}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                        this.setState({ code: '' });
                        this.setState({ swift: '' });
                        this.setState({ suspenseAccount: '' });
                        this.setState({ interbankSuspenseAccount: '' });
                        this.setState({ p2BSuspenseAccount: '' });
                        this.setState({ p2BInterbankSuspenseAccount: '' });
                    }}
                    onConfirm={this.deleteBankHandler}
                    title={'Delete Bank'}
                    confirmText={'Delete Bank'}
                    loading={loading}
                ><p>Are you sure you want to delete this bank?</p></Dialog>

                <ToastContainer />
            </Fragment >
        );
    }
};

export default Banks;